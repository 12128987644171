import React, { useState } from "react";
import Navigation from "../Navigation";
import Footer from "../Footer";
import FooterDownload from "../FooterDownload";
import QRCode from "../QRCode";
import { linkConfig } from "../../config";
import styles from "./styles.module.scss";
import { downloadNow } from "../../utils";
import { parse } from "qs";
import { useLocation } from "react-router-dom";

export default ({
  children,
  className = "",
  retModalOne_title = "开户可领取50元红包,确定放弃吗？",
  retModalOne_confirmTxt = "继续领取",
  retModalOne_cancelTxt = "确认放弃",
  retModalTwo_title = "下载APP领取$11000赠金！",
  retModalTwo_confirmTxt = "立即下载",
  retModalTwo_cancelTxt = "暂不领取",
  retModalTwo_cancelFunc = () => {
    window.location.href = linkConfig.web;
  },
  isPageSkipDisplay = false,
}) => {
  const [retModalOneVisible, setRetModalOneVisible] = useState(false);
  const [retModalTwoVisible, setRetModalTwoVisible] = useState(false);
  const [qrcodeVisible, toggleQrcodeVisible] = useState(false);

  const location = useLocation();
  const params = parse(location.search.slice(1));

  const isApp = () => {
    if (params.from instanceof Array) {
      return params.from.includes("app");
    } else {
      return params.from === "app";
    }
  };
  const clientWidth = document.body.clientWidth || 1080;
  const isPcView = clientWidth > 1080;
  const isNotFooterDownloadShow = isApp() || isPcView;

  const toggleRetModalOne = () => {
    setRetModalOneVisible((t) => !t);
  };

  const toggleRetModalTwo = () => {
    setRetModalOneVisible((t) => !t);
    setRetModalTwoVisible((t) => !t);
  };

  const handleAgainConfirm = () => {
    if (isPcView) {
      toggleQrcodeVisible((v) => !v);
    } else {
      downloadNow();
    }
  };

  const handleAgainCancel = () => {
    setRetModalTwoVisible((t) => !t);
    retModalTwo_cancelFunc();
  };

  return (
    <main>
      <Navigation submit={toggleRetModalOne} />
      <div className={`${styles.main} ${className}`}>
        {children}
        {isPageSkipDisplay && (
          <div className="skip-btn" onClick={toggleRetModalOne}>
            {" "}
            跳过{" "}
          </div>
        )}
      </div>

      {/* 第一个挽留弹窗 */}
      {retModalOneVisible && (
        <div className={styles.modal}>
          <div>
            <h3>{retModalOne_title}</h3>
            <div>
              <span className={styles.button1} onClick={toggleRetModalOne}>
                {retModalOne_confirmTxt}
              </span>
              <span className={styles.button2} onClick={toggleRetModalTwo}>
                {retModalOne_cancelTxt}
              </span>
            </div>
          </div>
        </div>
      )}

      {/* 第二个挽留弹窗 */}
      {retModalTwoVisible && (
        <div className={styles.modal}>
          <div className={styles.modalTwo}>
            <h3>{retModalTwo_title}</h3>
            <div>
              <span className={styles.button1} onClick={handleAgainConfirm}>
                {retModalTwo_confirmTxt}
              </span>
              <span className={styles.button2} onClick={handleAgainCancel}>
                {retModalTwo_cancelTxt}
              </span>
            </div>
            {qrcodeVisible && (
              <div className={styles.qrcodewrap}>
                <span className={styles.close} onClick={() => toggleQrcodeVisible((v) => !v)}></span>
                <QRCode width={240} />
                <i>扫码下载</i>
              </div>
            )}
          </div>
        </div>
      )}
      <Footer />
      {isNotFooterDownloadShow ? null : <FooterDownload />}
    </main>
  );
};
