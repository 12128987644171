import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Risk, Agreement, PrivacyPolicy, Disclaimer } from "./content";

export default () => {
  const { type } = useParams();
  const currentContent = useMemo(() => {
    switch (type) {
      case "client_agreement":
        return <Agreement />;
      case "risk_disclosure":
        return <Risk  />;
      case "privacy_policy":
        return <PrivacyPolicy />;
      case "disclaimer":
        return <Disclaimer />
      default:
        return <Agreement />;
    }
  }, [type]);

  return (
    <div className="container">
      {currentContent}
    </div>
  );
};
