import React, { useEffect, useRef } from "react";

import QRCode from "qrcode";

import { linkConfig } from "../../config";

export default ({ width }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current) {
      showCanvas(canvasRef.current, linkConfig.h5);
    }
    function showCanvas(dom, url) {
      QRCode.toCanvas(
        dom,
        url,
        { width: width, height: width },
        function (error) {
          if (error) console.error(error);
        }
      );
    }
  }, [canvasRef, width]);

  return <canvas ref={canvasRef}></canvas>;
};
