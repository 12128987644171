import axios from "axios";

const validContentType = [
  "application/octet-stream",
  "application/zip",
  "application/vnd.android.package-archive",
  "application/x-msdownload",
];

/**
 * @private
 * @param {string} url 
 * @return {Promise<boolean>}
 */
async function isUrlDownloadable(url) {
  if (typeof url !== "string" || url === "") {
    return false;
  }
  try {
    const response = await axios.head(url, { responseType: "stream" });
    const contentType = response?.headers?.["content-type"] || "";
    if (!validContentType.includes(contentType)) {
      console.log(`Unexpected content type: ${contentType}`);
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
}
/**
 * @static
 * @param {string} url 
 */
export function excuteDownload(url) {
  if (typeof url !== "string" || url === "") {
    return;
  }
  console.log("create a tag to download");
  const links = document.createElement("a");
  links.href = url;
  links.setAttribute("rel", "noopener noreferrer");
  document.body.appendChild(links);
  links.click();
  document.body.removeChild(links);
  return true;
}

/**
 * 接收傳入連結，先確認連結可以下載
 * @static
 * @param {Array | String} url or url array 
 */
export const handleDownloadProcess = async (list) => {
  let urls = []
  if (typeof list === "string" && list !== "") {
    urls.push(list)
  } else if (Array.isArray(list)) {
    urls = list
  } else {
    return new Error("传入参数错误");
  }
  try {
    let url;
    for (const currentUrl of urls) {
      console.log("currentUrl: " + currentUrl);
      const result = await isUrlDownloadable(currentUrl);
      if (result) {
        url = currentUrl;
        break;
      }
    }
    if (typeof url === "string") {
      excuteDownload(url);
      return true;
    } else {
      throw new Error("无可下载链结");
    }
  } catch (e) {
    throw new Error("下载发生错误" + e)
  }
}
