import React, { useState, useRef, useCallback } from "react";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { updateAccount } from "../services";
import Utils, { getAgeByIDCard } from "../utils";
import { linkConfig } from "../config";
import Layout from "../components/Layout";
import { KycSuccessModal } from "../components/Modal";
import styles from "./styles.module.scss";

export default () => {
  const formRef = useRef(null);
  const { updateCustomerUuid } = useParams(); // 追踪参数
  const [isSubmiting, setSubmiting] = useState(false); // 表单提交状态
  const [idValue, setIdValue] = useState(""); // 身份证号码
  const [visible, setVisible] = useState(false); // 完善资料成功弹窗
  const [tips, setTips] = useState({
    type: null,
    msg: null,
  });

  const setMessage = useCallback(({ type, msg, time = 5 }) => {
    setTips({ type, msg });
    const timer = setTimeout(() => {
      setTips({ type: null, msg: null });
      clearTimeout(timer);
    }, time * 1000);
  }, []);

  const isUnderAge = useCallback(() => {
    return getAgeByIDCard(idValue) < 18;
  }, [idValue]);

  const updateCustomer = useCallback(
    async (values) => {
      if (isUnderAge()) {
        setMessage({ type: "error", msg: "差价合约属高风险投资，不适合未成年人参与" });
        return;
      }
      setSubmiting(true);
      try {
        const res = await updateAccount({ ...values, updateCustomerUuid });
        if (res.code !== "OK") {
          setMessage({ type: "error", msg: res.msg });
          return;
        }
        setVisible(true);
      } catch (error) {
      } finally {
        setSubmiting(false);
      }
    },
    [updateCustomerUuid, setMessage, isUnderAge],
  );

  const handleAgainCancelFunc = () => {
    window.location.href = linkConfig.online;
  }

  return (
    <Layout
      className="flex-container layout-vertical"
      retModalOne_title="完善资料可领取50元红包，确定放弃吗?"
      retModalOne_cancelTxt="确认跳过"
      retModalTwo_title="开户成功"
      retModalTwo_cancelTxt = "谘询客服"
      retModalTwo_cancelFunc={handleAgainCancelFunc}
      isPageSkipDisplay={true}>
      <div className={styles.wrapper}>
        <div className="form flex-block">
          <Formik
            innerRef={formRef}
            initialValues={{
              chineseName: "",
              idDocumentNumber: "",
              email: "",
            }}
            validationSchema={yup.object().shape({
              chineseName: yup.string().required("请输入姓名").matches(Utils.validate.chineseName, "请输入正确姓名"),
              idDocumentNumber: yup
                .string()
                .required("请输入身份证号码")
                .matches(Utils.validate.idDocumentNumber, "请输入正确的身份证号码"),
              email: yup.string().matches(Utils.validate.email, "请输入正确的电子邮箱"),
            })}
            onSubmit={(values) => {
              updateCustomer(values);
            }}>
            {({ handleSubmit, touched, values, errors, setFieldValue, isValid, dirty, setFieldError }) => (
              <form onSubmit={handleSubmit}>
                <h2 className="page-title">
                  完善资料
                  <p className="page-title-desp">信息安全加密，仅用于实名认证。</p>
                </h2>
                <div className={`${(values.chineseName || touched.chineseName) && errors.chineseName ? "err" : ""} item`}>
                  <div className={`form-item`}>
                    <input
                      type="text"
                      placeholder="请输入姓名"
                      maxLength={11}
                      onChange={(e) => {
                        setFieldValue("chineseName", e.target.value);
                      }}
                    />
                  </div>
                  {(values.chineseName || touched.chineseName) && errors.chineseName && (
                    <div className="error">{errors.chineseName}</div>
                  )}
                </div>
                <div
                  className={`${
                    (values.idDocumentNumber || touched.idDocumentNumber) && errors.idDocumentNumber ? "err" : ""
                  } item`}>
                  <div className={`form-item`}>
                    <input
                      type="text"
                      placeholder="请输入身份证号"
                      onChange={(e) => {
                        const value = e.target.value.replace(/[\W]/g, "").toUpperCase();
                        setIdValue(value);
                        setFieldValue("idDocumentNumber", value);
                      }}
                      value={idValue}
                      maxLength={18}
                    />
                  </div>
                  {(values.idDocumentNumber || touched.idDocumentNumber) && errors.idDocumentNumber ? (
                    <div className="error">{errors.idDocumentNumber}</div>
                  ) : (
                    <div>{isUnderAge() && <div className="error">差价合约属高风险投资，不适合未成年人参与</div>}</div>
                  )}
                </div>

                <div className={`${(values.email || touched.email) && errors.email ? "err" : ""} item`}>
                  <div className={`form-item email`}>
                    <input type="text" placeholder="请输入邮箱" onChange={(e) => setFieldValue("email", e.target.value)} />
                  </div>
                  {(values.email || touched.email) && errors.email && <div className="error">{errors.email}</div>}
                </div>
                <div className={styles.tips_message}>{tips.msg && <p className={`${tips.type}`}>{tips.msg}</p>}</div>
                <div className="tools">
                  <button type="submit" disabled={isSubmiting} className="block-show btn btn-register">
                    {isSubmiting ? "提交中..." : "领取50元红包"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      {visible && <KycSuccessModal toggleVisible={() => setVisible((t) => !t)} />}
    </Layout>
  );
};
