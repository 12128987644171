import React from "react";
import styles from "./styles.module.scss";

const Risk = () => {
  return (
    <div className={styles.singlepage}>
      <h1>风险披露</h1>
      <div>
        <h2>市场风险和网上交易</h2>
        <p>
          合约产品交易涉及相当大的风险，其并非对每一位投资者都适合。请详细阅读此风险披露声明和本协议所有内容。虽然网上交易为客户带来很多方便及效率，但它可能增加外汇交易本身的风险。因此客户在交易前，须准备承担一切有关风险。
        </p>
      </div>
      <h2>只适合专业投资人士</h2>
      <p>合约产品交易仅适合专业机构或投资经验丰富的人士，其财政状况能够承受也许远超过保证金或存款金价值的损失。</p>
      <h2>交易设施及责任限度</h2>
      <p>
        合约产品的业务并不需公开喊价。虽然许多场所交易都以计算机为基础的系统提供报价和实际价格，但这两价格可能会因为市场不流动性而有所偏差。大部分电子交易设施是由以计算机为基础的系统来支援进行交易下单、执行、配对。该类系统易受到临时系统故障的影响。客户收回某些损失的能力可能受限于系统提供者、银行及／或其他金融机构或清算所等所设定的不同程度责任限度。
      </p>
      <h2>交易所场外交易</h2>
      <p>
        在合约产品市场交易上，我司可被允许进行场外交易。我司有权拒绝接受或保证任何订单。鉴于这些原因，平仓、评估价值、确定公平价格或评估风险可能很困难或不可能。再者场外交易可能受到较少的监管或受管于不同的监管体系。客户在开始交易之前，应该清楚了解适用的规定和相关风险。
      </p>
      <h2>杠杆式效应</h2>
      <p>
        合约产品交易是带有很高的风险。此类交易属于杠杆式交易，意指透过杠杆比例，借以让客户可以极低的初始保证金来参与金额极高的交易合约。即使市场上出现任何变动也会对客户的投资造成极大的影响，虽然这对客户也许有好的影响，但也有可能是坏的。客户有可能会为了保持持仓不被结算而需要承受客户存入我司的初始保证金及任何追加资金上的损失。如果市场变动对客户不利或者保证金水平提高，客户更有机会未能及时追加保证金来维持客户的持仓而在亏损的情况下被强制平仓。客户将必须对由此造成的亏损自行负责。
      </p>
      <h2>止损限价指令</h2>
      <p>
        损失限制在特定金额的指令（例如“止损限价”指令）有可能并不见效或无法执行。如果订单是停损限价单，我司无法保证订单能以限价执行或能成功地执行。此外，一些使用持仓合并的策略,
        例如差价或同价对冲与做“长仓”或“短仓”存在有相同的风险。
      </p>
      <h2>电子交易</h2>
      <p>
        通过电子交易系统进行的交易，在传送和接收客户指示或其他资料时可能出现延误、故障或传输中断。系统故障更可能造成客户的订单难以或无法按照客户的指示执行，甚至错误地执行。客户需接受电子交易系统带来的相关风险。
      </p>
      <h2>暂停或限制交易与定价的关系</h2>
      <p>
        市场状况及某些市场的运作条例（包括停市或合约产品暂停交易）也可能导致完成交易或平仓等交易指令有困难或不可能执行，因而增加客户损失。此外，相关资产与合约产品的正常价格关系可能不存在。由于缺乏相关资产的参考价格，评估或确定“公平”价格也变得困难或不可能。
      </p>
      <h2>在其他司法管辖区的交易</h2>
      <p>
        在其他司法管辖区市场（包括正式连接到本地市场的市场）进行的交易可能使客户需面对更多的风险。在那些市场的规定下，客户可能受到不同或较低的保障。在开始交易前客户应该询问任何与客户交易有关的规定，并了解本身所在地及其他司法管辖区可得到的保障。
      </p>
      <h2>交易对手的信誉</h2>
      <p>
        我司是不会保证客户的交易对手的信誉。我司只会尽力与有良好声誉及可靠的机构和清算所进行交易。此外，客户必须理解及明白有可能因为交易流动性的问题，使客户不能及时为亏损的持仓进行平仓，并为客户带来相当大的损失。
      </p>
      <h2>保证金及追加保证金</h2>
      <p>
        我司的保证金政策可能要求客户提供追加资金以便维持其保证金达至我司的指定水平及要求。客户有义务不时并及时满足这类保证金要求，否则其持仓将可能被自动平仓并造成相应的损失。我司保留有权拒绝接受订单或提供市场对冲。
      </p>
      <h2>汇率变动风险</h2>
      <p>
        我司之交易账户是以美元或我司不时同意之其他货币为单位。倘若客户以美元以外之其他货币存取款，客户必须承担因汇价变动而导致之任何收益或损失。客户并同意我司可按其全权决定的形式及时间对货币作出任何兑换，以实行其根据本协议而采取之任何行动或权利。
      </p>
      <h2>有关法例</h2>
      <p>
        客户应完全遵守当地有关法例，并同意付责因使用我司平台进行的交易而需支付当地任何相关税项、关税及其他金额。客户在我司平台进行交易将被视为其对我司发出声明及保证遵守当地法律及规定。
      </p>
      <h2>咨询法律及其它独立的专业意见</h2>
      <p>
        上述声明并不披露关于合约产品的所有风险及其交易上的一切重要事项。客户在开始进行合约产品交易之前，应该清楚了解有关交易性质和风险程度，并知悉合约产品交易并不适合一般投资者。客户必须根据其个人的投资经验、目的、财政状况和风险承受能力等相关因素仔细考虑合约产品交易是否适合客户本人。我司极力建议所有客户在开户及开始交易前应该咨询法律及其它独立的专业意见。
      </p>
    </div>
  );
};

const Agreement = () => {
  return (
    <div className={styles.singlepage}>
      <h1>客户协议</h1>
      <p>
        我司欢迎客户于其开设一个账户，并通过或透过客户在我司开设的账户不时向客户提供有关合约产品（如上文客户告鉴中所界定）的买卖。为此，我司及客户同意如下：
      </p>
      <h2>字词释义及标题</h2>
      <p>“我司”一词包括我司，其分部，继承人和转让人。</p>
      <p>“客户”一词指订立本协议的一方。</p>
      <p>
        “本协议”一词指客户与我司就有关账户（等）之开设、维持及运作而订立及不时修定之协议或给予的授权，其中包括但不限于本客户协议、客户告鉴、风险披露声明、开户申请表及一切给予我司的授权。本协议的段落标题是为查考方便而加入的，因此并不限制或影响段落条文的应用与意义。
      </p>
      <h2>协议接受及约束效力</h2>
      <p>
        仅当我司确认及审批客户之开户申请后，本协议方可视作已为我司所接受及成为客户与我司之间双方同意并具有约束力的合同。本协议（包括本客户协议、客户告鉴、风险披露声明、开户申请及一切给予我司的授权）一经我司如上文所接受将持续有效，并涵盖客户任何时候在我司开立或重新开立的所有账户，不论我司发生任何合并、兼并或其他变动。
      </p>
      <h2>交易授权</h2>
      <p>
        我司可以与客户部分或全部的买卖指令下达市场。我司获授权按照客户的口头、书面、传真或计算机网上指令向对手方如银行、金融机构或资深参与者为客户账户进行合约产品买卖。除非客户以书面形式作出反对，否则我司获授权向我司认为适合之对手方如银行、金融机构或资深参与者执行所有订单。我司有权依据所有从客户经口头、书面、传真或计算机网上收到的指示或指令，包括其雇员、合伙人、法定负责人（”有关授权人”），只要我司没有收到客户通知有关授权人并没有获得授权。客户授权我司可依据和执行似是从客户或其授权人所得来的任何指示、授权或信息。因此客户同意
      </p>
      <ul>
        <li>我司获授权执行指示是不需查询有关指示的有效性，有关指示会被视为授权人发出的有效指令；</li>
        <li>在任何情况下，我司不需核实指示的有效性或任何签名的真确性；</li>
        <li>
          在我司秉承行事和没有疏忽的情况下，客户将承担所有由任何代表人、雇员、或授权人发出未经批准指示的后果及风险，客户将为任何损失、费用、酬金、损毁、经费、索赔、诉讼或要求（等）负责。客户并保证不向我司追究责任或要求赔偿，并且我司不会因上述情况而导致损失，包括任何有关或产生自我司的实际行动、延迟实际行动或拒绝采取行动、由客户提供给我司的任何指示或资料，包括由客户的雇员、授权人或代表发出的不正当、未经授权、虚假或欺诈指示，即使该指示是没有获得客户授权。
        </li>
      </ul>
      <h2>执行订单酌情权</h2>
      <p>我司有权限制客户每次下单的总数。我司有权限制客户的任何时间可获得或可持有的持仓金额及／或总数。我司将按照客户计算机或录音电话的指示执行其选择接受的订单。我司有权拒绝接受任何订单或保证市场对冲。但是我司将不负责任何我司不可直接或间接控制的事件、行为或不行为所造成的损失或损害，这种情况包括但不限于任何由于传输或通讯设施故障造成的订单或信息传输的延迟或不准确带来的损失或损害。</p>
      <h2>挂单交易平台执行机制</h2>
      <p>客户明白及同意，交易平台是依照自身的执行机制处理订单，当同一时间，多张订单都到达有效价位时，执行顺序并非按照到价先后，交易单的建立顺序也会是判断因素之一。凡牵涉平台处理同时到价交易单的顺序问题，客户同意以交易平台最终成交价位的处理机制为准，我司对交易平台的处理机制有最终解释权。下达某些挂单交易有可能并不见效或没有执行指定价位。如果订单是止损限价单，无法保证订单以限价执行而不需事先做出通知。客户同意不就因挂单而损失的潜在盈利追究我司的责任。</p>
      <h2>政府、对手机构及银行间系统规条</h2>
      <p>所有本协议下的交易均受辖于执行交易的对手机构或其他银行间市场（及其清算组织，如适用）的宪章、细则、条例、规定、习惯、用法、裁决和解释，并执行所有适合的加拿大法律。如果此后通过的任何法令，或任何政府机构通过的任何条款，或对本协议产生影响／冲突的任何条款，将视作被有关法令、条规变更或替代，而其他条款及变更后的条款将继续完全生效。客户承认本协议下的所有交易受辖于前述监管要求。</p>
      <h2>交叉交易的同意</h2>
      <p>客户在此承认并同意下述情况有可能出现，即与我司相关的某一营业人员、董事、关联机构、关联人、雇员、银行或银行雇员、交易商及或我司本身可能是客户账户所进行的交易的对手、经纪人或委托人。客户在此同意进行上述交易时仅有的限制是有关执行买卖订单的银行、机构、交易所或交易委员会的任何可能的条例或规定，或其他监管机构的任何可能的限制与条件。</p>
      <h2>保证金和存取款</h2>
      <p>客户须向我司提供并维持由我司所不时订定的保证金金额或水平。根据市场状况，我司有权在任何时候改变保证金要求。客户同意当我司作出追补或增加保证金之要求时，客户须立即并迅速以我司所要求的存款方式满足所有保证金催促通知的要求，而任何存款过程涉及的费用，包括银行手续费、汇率差价及所有有关费用均由客户承担。由于处理客户之存款需时，客户有可能不可以实时使用刚存入的保证金作为新持仓的按金，更可能不可以作为追加保证金，客户同意承担一切由于未能及时满足追加保证金要求而面临强制平仓之损失，其损失可能超过客户的初始保证金，即账户内的所有资金。我司可能在任何时候根据本协议的规定强制客户的账户进行平仓，即使我司不行使该项权利，并不代表其放弃该权利。任何我司过去的保证金要求均不妨碍我司在不需提供任何理由或解释下提高上述保证金要求。客户有权随时通知我司提取指定的可用现金结余，客户同意任何汇款及转款过程涉及的费用，包括银行手续费、汇率差价及所有有关费用均由客户承担。客户完全理解我司及相关银行需要时间去处理有关客户之提款，客户很可能不可以实时收到所提取的金额。客户同意不追究我司由于未能及时满足其提款要求所引起的所有责任。当客户的提款要求一经确认，我司将实时从客户账户结余扣除该款额。客户必须确保其所指定的往来银行账户属于客户本人，我司均不接受第三方存取款。</p>
      <h2>账户清偿与欠款偿付</h2>
      <p>如发生任何一项或多项下列情形：</p>
      <ul>
        <li>客户身故、神经失常或司法宣定无能力；</li>
        <li>客户申请破产，或选派托管人，或客户自愿或被动地或任何人向法庭申请其破产、清盘或其他相类似的法律程序；</li>
        <li>客户的任何资产遭接管人或类似职位人员接管，或遭受任何扣押或判决之执行；</li>
        <li>不论当时的市场报价如何，客户的账户保证金金额不足；</li>
        <li>客户未能向我司提供任何根据本协议要求的相关资料或信息；或任何其他我司应当采取保护措施的情况或变化，我司拥有绝对酌情权在无须事前向客户作出要求提供保证金或追加保证金或任何通知下采取以下某种或多种行动:
          <ul>
            <li>我司把其代客户托管或控制的资金或财产用作抵偿客户对我司负的一切债务；</li>
            <li>买卖任何客户在其账户中持有的合约产品持仓；</li>
            <li>取消任何或所有未完成订单，或其他任何以客户名义作出的承诺, 不论涉及的利益是否客户独有或与他人合有。在上述买卖中，我司有绝对酌情权通过任何银行间或其他经常进行业务的交易市场中进行，且我司可以购买全部或部分而不受赎回权的限制。只要我司向客户作出要求，客户将立即对其账户的债务负责，包括相应利息(即银行优惠利率加３℅或法律规定的最高利率，以较低为准) 及其它相关费用。在其账户被我司或客户自己全部或部分平仓时或在任何时候客户均须对其剩余欠款负责。如客户名下有多个户口，我司有绝对酌情权自行决定合并客户名下该些户口，把任何账户盈余抵销任何账户的债务或不足。</li>
          </ul>
        </li>
      </ul>
      <h2>费用</h2>
      <p>客户将支付因我司所提供的服务产生的手续费和其他特别服务之所有费用（包括但不限于溢价和折价，报表费，闲置账户费，指令取消费，转账费和其他费用）及收费（包括但不限于由银行间机构、银行、合约市场或其他监管或自律组织收取的费用）。我司可能不经通知收取佣金、费用及/或收费。客户同意向我司支付其欠交款项的利息。所有这些费用将在发生时，或在我司根据其绝对酌情权决定下由客户支付。客户同意我司从其账户中扣除上述费用。在客户指示我司将其账户的未平仓持仓、资金、及/或财产转向其他机构时，客户同意支付由我司确定的转账费。我司确认所有向客户报出的价格不包括溢价与折价。</p>
      <h2>套利</h2>
      <p>互联网、连线延误、报价上的误差或报价系统的漏洞有时会造成在我司交易平台上的报价无法准确地反映实时市场价格。「套利」、「切汇」及「剥头皮」，或因网络连接的延误或采用外挂软件(即任何非我司发布的第三方辅助软件) 进行快速的建仓和平仓操作 而利用差价获利的行为(以下统称“套利”)，是不容许存在于客户直接向庄家进行买卖的场外交易市场中。我司不容许客户在其公司的交易平台上进行此等套利行为。任何客户利用潜在套利机会从事的交易可能会被取消。我司保留权利对涉及套利交易的账户进行必要的修正和调整。我司将拥有及依据绝对酌情权，要求交易员进行干预或核准所有下单以及或终止有关客户的账户，而不需事先通知客户。对套利或操控价格而产生的纠纷，我司将拥有及依据绝对酌情权进行解决。我司保留冻结客户提款的权利直至能够完全解决上述的问题为止。于此处陈述的任何行动或决定并不免除或损害我司对客户和其雇员拥有之任何权利或赔偿。</p>
      <h2>价格、订单执行及平台的操控</h2>
      <p>我司绝对严禁以任何形式对其价格、订单执行及平台进行操控(以下统称 “操控”)。若我司怀疑任何客户或账户从事操控，我司保留对该客户或有关账户进行调查及审核等的相关权利，并从涉嫌账户中扣除由相关活动所赚取的盈利款项。我司保留对相关账户进行必要更正或调整的权利。对于涉嫌从事操控的账户，我司依据绝对酌情权，要求交易员进行干预、对下单进行核准以及或终止有关客户的账户。对于由套利及或操控所产生的任何纠纷，我司将依据绝对酌情权进行解决。我司可依据酌情权决定向任何相关监管机构或执法机构报告有关事件。于此处所陈述的任何行动或决定并不免除或损害我司对客户和其雇员拥有之任何权利或赔偿。</p>
      <h2>抵押协议</h2>
      <p>客户的所有资金、货币及其它财产，如为我司在任何时候代客户持有、掌管或控制，此类财物将被我司作为担保物，并可因客户对我司的义务受限制于普通留置权及对冲权。我司可在不通知客户的情况下行使其绝对酌情权在任何时候将客户的所有资金、货币或其他财产投入或转进客户的任何账户。客户亦授权我司以单独地或者与其他客户的财产一起的形式，将客户作为保证金或担保物的任何财产进行抵押、再抵押、投资或出借于我司自身或其他方。此授权适用于所有我司为客户开持的账户，并在客户全额付清所有债务之前、或我司发出撤销通知之前有效。</p>
      <h2>清算日与延展</h2>
      <p>客户的所有合约产品持仓会在交易日以美元显示在客户的账户上，并在48小时内清算，并以美元作为交收货币。持仓款项会在额外48小时后自动过仓，除非</p>
      <ul>
        <li>客户给予令我司满意的指示交割，这要按照我司的惯例、通常收费和重新交付费用；</li>
        <li>我司接受了客户的下单可有全权酌情决定对冲合约产品持仓。客户应在合约产品清算日的前一个工作天正午之前指示是否交割或对冲。如无客户的及时指示，我司将被视为得到客户的授权全权及酌情地决定是否延展所有或任何客户在我司账户中的货币持仓。一切相关风险均由客户承担。</li>
      </ul>
      <h2>录音</h2>
      <p>客户同意我司有权采取录音程序录取及用作确实客户指示或通讯。客户进一步同意在涉及任何客户与我司的纠纷或诉讼中，任何一方可以使用此类录音或眷本作证据。客户理解并同意我司可定期根据其采用的营业模式清除这类录音。</p>
      <h2>第三方操控者</h2>
      <p>我司绝对不允许客户将交易授权或对其账户的管理交予第三者, 不论是以自主权或非自主权的基础上。</p>
      <h2>客户声明及承诺</h2>
      <ul>
        <li>客户本人是头脑健全、达法定年龄、并具有法律能力；</li>
        <li>只有账户持有人/客户本人享有其账户之利益；</li>
        <li>客户有足够投资经验、及财政能力承受合约产品交易上的风险；</li>
        <li>客户并不受雇于任何交易所、交易所持有绝大部分资本的公司、交易所的成员及或在交易所注册的公司、银行、信托机构或保险公司;</li>
        <li>客户提供的信息(包括其一切个人资料)皆为真实、准确和完整;</li>
        <li>客户将完全遵守当地有关法例，包括为遵守该等地区或司法管辖区内须遵守之规定辨理一切必要手续以取得政府或其他方面之同意，以及因为使用本平台进行交易而需要支付当地的任何税项、关税及其他金额；</li>
        <li>客户不会进行任何套利或操控的活动;</li>
        <li>明白本协议的所有条款，包括但不限于上述客户告鉴、风险披露声明;</li>
        <li>客户明白及同意执行客户根据本协议的一切义务、责任及承诺;</li>
        <li>若上述任何声明及承诺因任何原因出现不正确，客户有责任立即以书面形式通知我司。</li>
      </ul>
      <h2>密码保护</h2>
      <p>客户必须将我司提供给客户的密码保密并确保没有第三者取用其交易设施。客户同意对所有经由电邮、电子交易平台、电话或书面向我司发出的指示确实负责，即使是由第三者发出，只要这些指示已和客户密码或客户签名和账户号码认证，并根据我司的判断相信这是客户表面授权。我司并没有责任对这些表面授权作进一步查询，也没有责任对因为依据这些表面授权所采取的或不采取的行动所造成之后果负责。客户须对密码的保密性、安全性及其使用独自承担责任。</p>
      <h2>免责条款</h2>
      <ul>
        <li>客户明白、理解及同意我司将不会因本协议(包括但不限于上述客户告鉴、风险披露声明)中所述的任何一项或多项风险引至客户的一切损失（不论是直接或间接的）承担任何责任。</li>
        <li>客户明白、理解及同意我司将不会对客户因我司行驶或不行驶或如何行驶其任何酌情权或根据此协议 (包括但不限于在发现套利或操控活动的情况下) 给予我司的权利而受到的一切损失（不论是直接或间接的）承担任何责任。</li>
        <li>客户明白、理解及同意我司将不会对客户在本协议(包括但不限于上述客户告鉴、风险披露声明)中帮助由客户自行负责的损失及或责任（不论是直接或间接的）承担任何责任。</li>
        <li>报价错误,当某些报价或成交价出现错误时，我司将不为此错误所导致的账户结余相应错误负责。有关错误可能包括但不限于：交易员的错误报价、非国际市场价之报价、或是任何报价错误（例如由于硬件、软件或通讯或系统网络故障导致报价错误或者第三者提供了错误的数等等）。我司不需为错误所导致的账户余额负责。客户下单时需预留足够的时间执行订单和系统计算所需保证金的时间。当订单的执行价格或订单设定和市场价格过于接近，这可能会触发其他订单（不论是那种订单类型）或发出保证金提示。我司不会对由于系统没有足够时间执行订单或进行运算所产生的保证金提示、账户结余或账户持仓负责。上文不得视作内容尽列。一旦发生报价或执行错误，我司保留对有关账户进行必要更正或调整的权利。对于报价或执行错误而产生的任何争议，我司将拥有及依据绝对酌情权进行解决。若因此带给我司任何损失、损害或责任，客户同意予以赔偿使我司不受损害。</li>
        <li>所有客户必须意识到任何回报是非保证的。此外，我司不对任何我司、其雇员、及/ 或任何其他人士作出的指称或保证负责。</li>
        <li>我司将不负责因传输或通讯设施故障、电力短路或任何其他我司所不能控制或预计的原因带来的指令传输的延迟。 我司将仅对直接因为我司的蓄意过错或欺诈造成的行为负责。我司将不对其他方面过错而引起的损失负责。</li>
      </ul>
      <h2>通讯联络</h2>
      <p>报告、报表、通讯及其它通知可能送达至客户于开户申请时所提供的电邮地址或住址或客户不时以书面或网上登入方式向我司更新的电邮地址或住址。所有如此送出的报告、报表、通讯及其它通知，不论是邮寄、电邮或其他方式，一旦投入有关邮政机构，或经发送机构接收，即被认定已由我司发出，且被认定已送达客户本人，不论客户是否实际收到。</p>
      <h2>报表与确认</h2>
      <p>订单的确认报告（如日结单、月结单等）和客户的账户报表将被视作正确、终结、并对客户具有约束力，除非客户在收到我司网上交易平台或其他方式送达的报告两日之内立即作出书面反对。</p>
      <h2>协议修改</h2>
      <p>客户同意我司有权可不时修改或变更本协议之任何条款或增补任何新条款。我司会把这些修改、变更或增补在其公司网站公布及通知客户。客户须定时浏览有关条款之修改。客户在有关修改、变更或增补在我司网站公布后作出的任何买卖或交易指令将被视为同意及接受此有关修改、变更或增补的行为。除非有关修改、变更或增补是以上述形式由我司修订或由客户和我司共同签字作实，本协议的任何条款均不可被豁免或被修订。在任何情况下，我司未能坚持其在本协议的权利均不可被解释为我司对该权利的放弃。任何口头协议或指示均不可被承认或执行。</p>
      <h2>赔偿</h2>
      <p>客户同意，如因客户未能完全与及时地履行其承诺或因其声明或保证并不属实或正确，而给我司带来任何债务、损失、损害、成本或费用（包括律师费），客户将对此向我司作出百分百的赔偿，并使之不受损害。</p>
      <h2>权利转让</h2>
      <p>我司可在无需客户同意或批准的情况下，将本协议之全部或部分权利或义务授予任何人。</p>
      <h2>终止</h2>
      <p>本协议将持续有效直至终止。客户可在任何时候终止本协议，但客户必须没有未平仓现货合约产品持仓及不对我司负有任何债务，且我司实际收到终止协议的书面通知。我司也可任何时候向客户提出终止协议的书面通知，并在发出通知当日收市随后第一个结算时间起开始生效。上述本协议的终止将不影响任何之前签订的交易且不解除任何一方在此协议下的任何义务，或解除客户的任何债务责任。</p>
      <h2>信用状况</h2>
      <p>客户授权我司，或其雇员以我司的名义，调查客户的信用状况并与有关的银行、金融机构和信用机构查证及确定客户资料。客户进一步授权我司调查其目前和过去的投资活动。</p>
      <h2>管辖法律及司法管辖权</h2>
      <p>本协议根据加拿大的法律解释与执行。</p>
      <h2>协议的整体和全部内容</h2>
      <p>本协议连同开户申请时参阅文件和有关附件构成本协议主题整体和全部内容。本协议将取代所有过去经双方签署或承诺的与本协议主题，杠杆式合约产品交易相关的书面或口头协议。</p>
    </div>
  );
};

const Disclaimer = () => {
  return (
    <div className={styles.singlepage}>
      <h1>免责声明</h1>
      <p>本网站的资料，只作一般性质及说明用途。对该等资料，我司不会就任何错误、遗漏、或错误陈述或失实陈述（不论明示或默示的）承担任何责任。对任何因使用或不当使用或依据我司所载的资料而引致或所涉及的损失、毁坏或损害 （包括但不限于相应而生的损失、毁坏或损害），我司概不承担任何义务、责任或法律责任。</p>
      <p>为方便使用者查阅由其他人士/团体或通过其他人士/团体提供的资讯，本网站可能会提供或协助提供超链接至外界网站。提供或协助提供该等超链接，并不构成我司就赞同或不赞同该等网站之内容作出任何明示或暗示的声明、陈述或保证；对任何因使用或不当使用或依据通过我司网站传递或提供的外界网站的内容而引致或所涉及的损失、毁坏或损害（包括但不限于相应而生的损失、毁坏或损害），我司概不承担任何义务、责任或法律责任。</p>
      <p>本网站所提供的所有资料均为我司之财产。任何人士如未经我司预先书面授权同意，不得以任何理由及形式散发此网站的文字或图档或音像档或在阁下之伺服器内重制、修改或重用此系统内容的文字及图档或音像档。阁下可以打印或储存有关资料于个人电脑内，以供个人使用或参考用途，但须同时保留内容所载的所有版权及其他专有通告。</p>
      <p>本网站并非供禁止发布或提供本网站的任何司法管辖区内的任何人士使用（基于该名人士的国籍、居留地或其他原因）。有关禁制的人士不得使用本网站。使用本网站所载资料的任何人士有责任遵守其有关司法管辖区所有的适用法例及规例。阁下继续使用本网站，即代表阁下陈述及保证阁下的司法管辖区的适用法例及规例容许阁下浏览有关资料。</p>
      <p>本网站不应视为向任何司法管辖地区内的任何人士提出其进行买卖任何产品或服务的要约或邀请，即使这些行为可能于相关地区被视为非法行为。</p>
      <p>保证金交易及买卖具一定风险，价格可升可跌，阁下需知悉并了解相关风险。</p>
      <p>当阁下一旦使用本网站，即表示阁下无条件接受上述所载的免责条款以及任何我司不时在无须预先通知阁下的情况下而对上述条款所作出的修改及/或修订。请定期浏览此网页查阅任何修改及/或修订。</p>
    </div>
  )
}

const PrivacyPolicy = () => {
  return (
    <div className={styles.singlepage}>
      <h1>隐私政策</h1>
      <p>我司的个人资料私隐政策是根据加拿大私隐条例保护客户权益，隐私政策规定了我们只通过您登录本平台、在本平台上注册和/或使用我们提供的服务采集您信息的类型以及我们如何使用和保护这些采集到的信息。</p>
      <p>1、为确保您对我们在处理个人资料上有充分信心,请您务必详细阅读及理解隐私政策的条文。特别是您一旦登录本平台不论您是否在本平台上注册，均向我们表明您接受、同意、承诺和确认:</p>
      <p>1.1 您具有完全民事权利能力和行为能力，或虽不具有完全民事权利能力和行为能力,但点击同意注册按钮，我司即视为经其法定代理人同意并由其法定代理人代理使用我司的服务；</p>
      <p>1.2 您在自愿下同意向我们披露个人资料;</p>
      <p>1.3 您会遵守本隐私政策的全部条款和限制;</p>
      <p>1.4 您同意我们通过您登录本平台、在本平台上注册和/或使用我们提供的服务收集您的信息；</p>
      <p>1.5 您同意日后在无需另行通知您的情况下对隐私政策的任何修改，请定期浏览本网页查阅最新条款;</p>
      <p>1.6 您同意我们的分公司、附属公司、雇员、就您可能会感兴趣的产品和服务与您联络(除非您已经明确表示不想收到该等讯息)。</p>
      <p>2、当您使用本平台时，您同意我们开始使用cookies来追踪您的每一个动作，并收集和记住你留下的所有信息，其中包括但不限于您的IP地址，地理位置及其他资料。 如果您愿意使用本平台提供的服务，您需要填写和提供以下两种信息：</p>
      <p>2.1 身份信息。该信息可以帮助我们验证您是否有资格注册为本平台会员，包括但不限制你的名字，你的居住地址，邮寄地址，您所属国家或政府发签发的其他具有证明您身份信息的不同的证书以及涉及到的号码及所有其他可帮助我们验证您身份的信息。</p>
      <p>2.2 服务信息。该信息帮助我们与您联系并顺利为您提供服务，包括但不限制您的电话号码、传真号码、有效的电子邮件地址、邮寄地址以及您的借记卡信息和/或其他帐户信息（以下合称为“服务信息”）。</p>
      <p>3、在您使用本平台或其提供的服务时，我们可能会为了改进本平台的使用功能，提升您对使用本平台及其提供的服务及其安全性的体验或是根据法院或可适用法律法规或有管辖权的其他政府机构的命令，通过本平台公示的专属于我们的邮箱或其他我们认为合规的方式收集更多的必要的信息。</p>
      <p>4、如果您访问了任何链接在本平台上的其他第三方平台或合作的第三方，您应同意并遵守该第三方网单独和独立的隐私政策。我们对这些平台或合作方的内容及活动不承担任何责任。</p>
      <p>5、我们可能会在客户的电脑设定及存取我司 Cookies，以协助我们分析并了解有关之市场推广策略的效果。我司及其雇员可能会于提供的产品和服务使用Cookies 来追踪客户在其网站的浏览，Cookies常用于记录访问者浏览我们平台上的各个项目时的习惯以及偏好。Cookies所搜集的资料是不记名的集体统计数据,不载有个人资料。</p>
      <p>6、APP将收集设备标识信息（IDFA），用于数据统计和内容服务，如果同意该服务，请用户自行勾选授权。</p>
      <p>7、我司致力于确保其网站是安全及符合业界标准，并且使用其他资料保障工具，例如：防火墙、认证系统（密码和个人身份证号码等）和操控机制来控制未获授权的系统进入网站和存取资料；</p>
      <p>8、我们不会向未获授权人士透露任何资料，但不包括以合约形式协助我司提供便利的平台服务予客户的公司，协助准备和邮寄月结单，执行必要的维护和开发等工作，只要这些当事方同意将这些信息保密。当我们相信披露信息是适当的，是遵守法律、法规、规章制度的或来自于法院或他主管当局的命令、执行我们的平台策略，正常运行平台所需要，或保护我们或他人的权利、财产或安全的。</p>
    </div>
  )
}

export {
  Risk,
  Agreement,
  Disclaimer,
  PrivacyPolicy
}
